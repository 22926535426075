<template>
    <section class="inventory-details">
        <div class="top-bar bg-white" style="justify-content: left">
            <div style="font-size: 28px">盘点详情</div>
        </div>
        <div style="padding: 10px;background-color: #ffffff;border-radius: 10px">
            <r-e-table class="bg-white" ref="tableRef" :dataRequest="blitemDetailList" :columns="tableColumn"
                       :query="formSearch" :height="750" :notAuto="true">
                <template slot="empty">
                    <el-empty/>
                </template>
                <el-table-column slot="toolbar" label="操作" width="150" fixed="right">
                    <template slot-scope="{ row }">
                        <div class="table-tools">
                            <span class="table-btn" v-if="row.statusCode===0" @click="wanc(row)">完成</span>
                        </div>
                    </template>
                </el-table-column>
            </r-e-table>
        </div>
    </section>
</template>

<script>
    import {inventoryDetailsTableColumn} from "@/views/property-management/assets-management/data";
    import {blitemDetailList,blitemPubCheck} from '@/api/inventory';
    import {MessageSuccess, MessageError, MessageWarning} from "@custom/message";
    export default {
        name: "inventory-details",
        data() {
            return {
                tableColumn: inventoryDetailsTableColumn(this),
                formSearch: {
                    blitemUuid:''
                }
            };
        },
        components: {},
        methods: {
            blitemDetailList(params){
                let { formSearch } = this;
                const paramsData={...params,...formSearch}
                return blitemDetailList(paramsData);
            },
            handleSearch(){
                this.$refs["tableRef"].pNumber = 1;
                this.$refs["tableRef"].getTableData();
            },
            wanc(data){
                this.$confirm('此操作将完成该盘点,是否继续?', '注意', { type: 'warning'
                }).then(async () => {
                    const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                    let {assetDetail: {uuid}} = data;
                    let {blitemUuid} = this.formSearch;
                    let paramData = {assetsUuid: uuid, blitemUuid};
                    blitemPubCheck(paramData).then(res => {
                        MessageSuccess("完成盘点成功");
                        loading.close();
                        this.handleSearch();
                    }).catch(err => {
                        MessageError("完成盘点失败");
                        loading.close();
                    });
                }).catch(() => {});
            },
        },
        async mounted() {
            let {params: {data}} = this.$route;
            if (data && typeof (data.uuid) !== 'undefined') {
                const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                let {uuid} = data;
                this.formSearch.blitemUuid = uuid;
                this.handleSearch();
                loading.close();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .inventory-details {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(10px);
            padding: VH(15px) VW(15px);
            justify-content: center;

            .el-radio-button.is-active {
                box-shadow: 0 0 10px #DDD inset;
            }
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }

        .title {
            height: VH(50px);
            line-height: VH(50px);
            color: #666;
            padding-left: VW(10px);
            position: relative;

            &::before {
                width: 5px;
                height: 40%;
                background-color: #5C84FB;
                content: '';
                position: absolute;
                left: 0;
                top: 30%;
            }
        }
    }
</style>